import "./styles.scss"

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

const Header = ({ isHome, isAnimated }) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 10)

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  })

  const animation = {
    style: { transition: "none" },
    initial: { y: "-100%" },
    animate: { y: 0 },
    transition: { type: "tween", duration: 0.5, delay: 1 },
  }

  return (
    <motion.header
      className={`header${isScrolled ? " header--shrink" : ""}`}
      {...(isHome && isAnimated ? animation : {})}
    >
      <Link to="/" className="header__logo">
        <img src={require("assets/images/logo.svg").default} alt="" />
      </Link>
      <nav className={`header__nav${isMenuOpen ? " header__nav--open" : ""}`}>
        <Link to="/o-inwestycji/" activeClassName="current">
          O inwestycji
        </Link>
        <Link to="/udogodnienia/" activeClassName="current">
          Udogodnienia
        </Link>
        <Link to="/o-deweloperze" activeClassName="current">
          O deweloperze
        </Link>
        <Link to="/dla-inwestora/" activeClassName="current">
          Dla inwestora
        </Link>
        <Link to="/lokalizacja/" activeClassName="current">
          Lokalizacja
        </Link>
        <Link to="/mieszkania/" activeClassName="current">
          Mieszkania
        </Link>
        {/* <Link to="/strefa-klienta/" activeClassName="current">
          Strefa klienta
        </Link> */}
        <Link to="/kontakt/" activeClassName="current">
          Kontakt
        </Link>
        <a href="tel:+48531553839" className="header__cta">
          531 553 839
        </a>
      </nav>
      <button
        aria-label="Open Menu"
        className={`hamburger hamburger--squeeze${
          isMenuOpen ? " is-active" : ""
        }`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <span key={index} />
          ))}
      </button>
    </motion.header>
  )
}

export default Header
