import "./styles.scss"

import React, { useEffect } from "react"
import { motion } from "framer-motion"

const Hero = ({ isAnimated, isBrowser, setIsAnimated, setIsPopupOpen }) => {
  const heroAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: {
      type: "tween",
      duration: 0.5,
      delay: 0.5,
    },
  }

  const wrapperAnimation = {
    initial: { y: "100%" },
    animate: { y: 0 },
    transition: { type: "tween", duration: 0.5, delay: 1 },
    onAnimationComplete: definition => {
      if (definition.y === 0) {
        if (isBrowser) {
          window.sessionStorage.setItem("animated-entry", "init")
        }

        if (setIsAnimated && typeof setIsAnimated === "function") {
          setIsAnimated(false)
        }
      }
    },
  }

  useEffect(() => {
    if (!isAnimated && isBrowser) {
      if (!window.sessionStorage.getItem("popup-visited")) {
        setTimeout(() => {
          setIsPopupOpen(true)
        }, 1000)
      }
    }
  }, [isAnimated, isBrowser, setIsPopupOpen])

  return (
    <motion.section
      className="home-hero"
      {...(isAnimated ? heroAnimation : {})}
    >
      <div className="container-fluid">
        <motion.div
          className="home-hero__wrapper"
          {...(isAnimated ? wrapperAnimation : {})}
        >
          <h1>
            Ekskluzywne
            <br />
            <span>apartamenty</span>
            <br />w sercu Krakowa
          </h1>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Hero
