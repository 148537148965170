import "./styles.scss"

import React from "react"
import { navigate } from "gatsby"

const Button = ({ children, type, disabled, onClick, className, to }) => {
  return (
    <button
      className={`button ${className ? className : ""}`}
      type={type}
      disabled={disabled}
      onClick={to ? () => navigate(to) : onClick}
    >
      {children}
    </button>
  )
}

export default Button
