import "styles/app.scss"
import "react-toastify/dist/ReactToastify.css"

import React, { useState, useLayoutEffect } from "react"
import { CookiesProvider, withCookies } from "react-cookie"
import { motion } from "framer-motion"
import { toast } from "react-toastify"

import {
  Seo,
  Header,
  Footer,
  CookieBaner,
  ContactForm,
  PopUp,
} from "./components"
import Hero from "page_components/home/Hero"

const Layout = ({ children, seo = {}, isHome }) => {
  toast.configure()

  const isBrowser = typeof window !== "undefined"
  const [isAnimated, setIsAnimated] = useState(true)
  const [isContentAnimated, setIsContentAnimated] = useState(true)
  const isRendered = !isHome || !isAnimated

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useLayoutEffect(() => {
    if (isBrowser) {
      if (window.sessionStorage.getItem("animated-entry")) {
        setIsAnimated(false)
        setIsContentAnimated(false)
      }
    }
  }, [isBrowser])

  return (
    <CookiesProvider>
      <Seo {...seo} />

      {isAnimated ? (
        <Header key="animated" isHome={isHome} isAnimated />
      ) : (
        <Header key="static" isHome={isHome} />
      )}

      <main>
        {isHome && (
          <>
            <PopUp
              isOpen={isPopupOpen}
              setIsOpen={setIsPopupOpen}
              isBrowser={isBrowser}
            />
            {isAnimated ? (
              <Hero
                key="animated"
                isAnimated
                isBrowser={isBrowser}
                setIsAnimated={setIsAnimated}
              />
            ) : (
              <Hero
                key="static"
                setIsPopupOpen={setIsPopupOpen}
                isBrowser={isBrowser}
              />
            )}
          </>
        )}
        {isRendered && (
          <AnimatedMainWrapper isHome={isHome} isAnimated={isContentAnimated}>
            {children}
            <ContactForm />
          </AnimatedMainWrapper>
        )}
      </main>

      {isRendered && (
        <>
          <Footer />
          <CookieBaner />
        </>
      )}
    </CookiesProvider>
  )
}

const AnimatedMainWrapper = ({ children, isHome, isAnimated }) =>
  isHome && isAnimated ? (
    <motion.div
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ type: "tween", duration: 0.3 }}
    >
      {children}
    </motion.div>
  ) : (
    children
  )

export default withCookies(Layout)
