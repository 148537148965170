import "./styles.scss"

import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"

const PopUp = ({ isOpen, setIsOpen, isBrowser }) => {
  const query = useStaticQuery(graphql`
    query {
      allWp {
        nodes {
          opcjeMotywu {
            acfThemeOptions {
              popupImage {
                sourceUrl
              }
              popupSettings {
                link {
                  value
                  target
                }
                colorInvert
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allWp.nodes[0].opcjeMotywu.acfThemeOptions

  const isImageAvailable = !!data.popupImage?.sourceUrl
  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    if (isBrowser) {
      if (isImageAvailable) {
        if (isOpen) {
          document.body.style.overflow = "hidden"
        } else {
          document.body.style.overflow = ""
        }
      } else {
        window.sessionStorage.setItem("popup-visited", "true")
      }
    }

    return () => (document.body.style.overflow = "")
  }, [isOpen, isImageAvailable, isBrowser])

  const handleClose = () => {
    window.sessionStorage.setItem("popup-visited", "true")

    if (setIsOpen && typeof setIsOpen === "function") {
      setIsOpen(false)
    }
  }

  return (
    <AnimatePresence mode="wait">
      {isImageAvailable && isOpen && (
        <motion.div
          className="pop-up"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "tween", duration: 0.3 }}
        >
          <motion.div
            className="pop-up__dialog"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 30, opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.3,
              delay: isInit ? 0 : 0.3,
            }}
            onAnimationComplete={definition => {
              if (definition.opacity === 1) {
                setIsInit(true)
              }
            }}
          >
            <div
              className={`pop-up__content${
                data.popupSettings.colorInvert ? " pop-up__content--light" : ""
              }`}
            >
              <button
                className={`pop-up__close${
                  data.popupSettings.colorInvert ? " pop-up__close--dark" : ""
                }`}
                onClick={handleClose}
              >
                Zamknij
              </button>
              <LinkWrapper
                to={data.popupSettings.link.value}
                isBlank={data.popupSettings.link.target}
                setIsOpen={setIsOpen}
                onRedirect={handleClose}
              >
                <img src={data.popupImage.sourceUrl} alt="Pop-up banner" />
              </LinkWrapper>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const LinkWrapper = ({ to, children, isBlank, onRedirect }) =>
  !!to ? (
    <a
      href={to}
      target={isBlank ? "_blank" : "_self"}
      rel="noreferrer"
      onClick={
        onRedirect && typeof onRedirect === "function"
          ? () => onRedirect()
          : undefined
      }
    >
      {children}
    </a>
  ) : (
    children
  )

export default PopUp
