import "./styles.scss"

import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"

import Button from "components/Button"

const CookieBaner = ({ cookies }) => {
  const [cookieBaner, setCookieBaner] = useState("")

  useEffect(() => {
    const isAcceptedCoookie = !!cookies.get("cookie-accept-mazowiecka-115")
    !isAcceptedCoookie && setCookieBaner(true)
  }, [cookies])

  const acceptCookieBaner = e => {
    e.preventDefault()
    const promiseSetCookie = new Promise(resolve =>
      resolve(
        cookies.set("cookie-accept-mazowiecka-115", "active", { path: "/" })
      )
    )
    promiseSetCookie.then(() => {
      setCookieBaner(false)
    })
  }

  return (
    <div className={`cookie-baner${cookieBaner ? " cookie-baner--open" : ""}`}>
      <p>
        Korzystamy z plików cookies w celu optymalizacji usług. Jeśli korzystasz
        z naszej witryny bez zmiany ustawień dotyczących cookies oznacza, że
        wyrażasz zgodę na zamieszczanie ich przez nas w Twoim urządzeniu.{" "}
        <a href="/polityka-prywatnosci/" target="_blank">
          Czytaj więcej
        </a>
      </p>
      <Button onClick={e => acceptCookieBaner(e)}>zgadzam się</Button>
    </div>
  )
}

export default withCookies(CookieBaner)
