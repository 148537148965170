/* eslint-disable react/jsx-no-target-blank */
import "./styles.scss"

import React from "react"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="footer__wrapper">
          <div>
            <img src={require("assets/images/logo-light.svg").default} alt="" />
          </div>
          <div>
            <p>Copyright &copy; {new Date().getFullYear()} Mazowiecka 115</p>
          </div>
          <div>
            <a href="https://adream.pl/" target="_blank">
              Proudly designed by
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
